export const KEYWORDS = [
  'javascript',
  'react',
  'angular',
  'html',
  'css',
  'framework',
  'jqurey',
  'object',
  'functional',
  'develop',
  'python',
  'c#',
  'c++',
  'c',
  'typescript',
  'rust',
  'java',
  'php',
  'swift',
  'sql',
  'mysql',
  'nosql',
  'ruby',
  'net',
  'test',
  'tdd',
  'nodejs',
  'algorithm',
  'variable',
  'program',
  'argument',
  'ascii',
  'bug',
  'code',
  'conditional',
  'data',
  'pointer',
  'backend',
  'frontend',
  'server',
  'syntax',
  'go',
  'sass',
  'cobol',
  'kotlin',
  'android',
  'apple',
  'visual',
  'github',
  'gitlab',
  'client',
  'build',
  'configure',
  'diverse',
  'integrity',
  'accountable',
  'linux',
  'windows',
  'mac',
  'macos',
  'web',
  'command',
  'communicate',
  'network',
  'troubleshoot',
  'vuejs',
  'sproutcore',
  'oriented',
  'oop',
  'keynote',
  'ui',
  'ux',
  'stakeholder',
  'async',
  'collaborate',
  'empathy',
  'api',
  'aws',
  'ubuntu',
  'jira',
  'spec',
  'migration',
  'maintain',
  'jenkins',
  'teamcity',
  'mongodb',
  'restful',
  'maven',
  'soap',
  'jboss',
  'weblogic',
  'websphere',
  'oracle',
  'cloud',
  'design',
  'blockchain',
  'pair-programming',
  'firebase',
  'graphql',
  'lambda',
  's3',
  'sqs',
  'devops',
  'engineer',
  'dynamodb',
  'splunk',
  'apache',
  'postgresql',
  'tailwind'
];